<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('log_point_coin.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('log_point_coin.head_list') }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-start">
          <v-col>
            <v-row no-gutters>
              <v-col cols="12" md="4" class="px-1">
                <span class="d-flex">
                  <date-picker-input
                    ref="startDate"
                    :date="dateFrom"
                    :label="$t('log_point_coin.date_from')"
                    @setValue="dateFrom = $event"
                  />
                  <!-- <v-dialog ref="dialog" v-model="modal2" :return-value.sync="time2" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time2"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="time-start"
                  ></v-text-field>
                </template>
                <v-time-picker v-if="modal2" v-model="time2" format="24hr">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false"> Cancel </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(time2)"> OK </v-btn>
                </v-time-picker>
              </v-dialog> -->
                </span>
              </v-col>
              <v-col cols="12" md="4" class="px-1">
                <span class="d-flex">
                  <date-picker-input
                    ref="endDate"
                    :date="dateTo"
                    :label="$t('log_point_coin.date_to')"
                    @setValue="dateTo = $event"
                  />
                  <!-- <v-dialog ref="dialog2" v-model="modal3" :return-value.sync="time3" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time3"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="time-start"
                  ></v-text-field>
                </template>
                <v-time-picker v-if="modal3" v-model="time3" format="24hr">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal3 = false"> Cancel </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog2.save(time3)"> OK </v-btn>
                </v-time-picker>
              </v-dialog> -->
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="4" class="px-1">
                <span class="d-flex">
                  <div
                    class="col-5 pa-0"
                    style="
                      height: 40px;
                      text-agent: center;
                      background: #e5e5e5;
                      border-radius: 8px;
                      color: #737076;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      padding: 0 1rem;
                    "
                  >
                    {{ $t('log_point_coin.activity') }}
                  </div>
                  <v-select :items="activityTypes" v-model="activityType" outlined dense />
                </span>
              </v-col>
              <v-col cols="12" md="4" class="px-1">
                <span class="d-flex">
                  <div
                    class="col-5 pa-0"
                    style="
                      height: 40px;
                      text-agent: center;
                      background: #e5e5e5;
                      border-radius: 8px;
                      color: #737076;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      padding: 0 1rem;
                    "
                  >
                    {{ $t('log_point_coin.item') }}
                  </div>
                  <v-select :items="itemTypes" v-model="itemType" outlined dense />
                </span>
              </v-col>
              <v-col cols="12" md="4" class="px-1">
                <span class="d-flex">
                  <div
                    class="col-5 pa-0"
                    style="
                      height: 40px;
                      text-agent: center;
                      background: #e5e5e5;
                      border-radius: 8px;
                      color: #737076;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      padding: 0 1rem;
                    "
                  >
                    {{ $t('log_point_coin.type') }}
                  </div>
                  <v-select :items="actionTypes" v-model="actionType" outlined dense />
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="4" class="px-1">
                <v-row no-gutters>
                  <v-text-field
                    v-debounce:500ms="searchKeyword"
                    :date="searchData"
                    outlined
                    dense
                    hide-details
                    :placeholder="$t('search')"
                    class="mr-2"
                  />
                  <v-btn color="primary" @click="searchPointCoinHistory"> {{ $t('search') }} </v-btn>
                  <!-- <v-btn color="warning" @click="preloadExportCsv" class=""> ออกรายงาน Excel </v-btn> -->
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-card-actions>

        <v-card-actions class="mt-7 pb-5">
          <div class="d-flex flex-row" style="width: 100%">
            <v-row>
              <v-col cols="12" md="4">
                <v-card style="border: 1px solid var(--v-success-base)">
                  <v-card-title class="d-flex flex-column justify-center layout text-center">
                    <h4>{{ $t('log_point_coin.block1_head') }}</h4>
                    <h5 class="mt-2 font-weight-regular">({{ dateFrom }} &nbsp;{{ $t('to') }}&nbsp; {{ dateTo }})</h5>
                  </v-card-title>
                  <v-divider />
                  <v-card-title class="justify-center layout">
                    {{ sumPoint.totalRemain | currency }}
                  </v-card-title>
                  <v-row class="ma-0 mb-4">
                    <v-col cols="6" class="pa-0 d-flex flex-column justify-center align-center">
                      <div class="d-flex flex-row justify-center align-center">
                        <v-icon color="success" small>mdi-arrow-up</v-icon>
                        <span class="success--text d-flex flex-row align-center justify-center">{{
                          $t('log_point_coin.type1')
                        }}</span>
                      </div>
                      <span>{{ sumPoint.totalAdd | currency }}</span>
                    </v-col>
                    <v-col cols="6" class="pa-0 d-flex flex-column justify-center align-center">
                      <div class="d-flex flex-row justify-center align-center">
                        <v-icon color="error" small>mdi-arrow-down</v-icon>
                        <span class="error--text d-flex flex-row align-center justify-center">{{
                          $t('log_point_coin.type2')
                        }}</span>
                      </div>
                      <span>{{ sumPoint.totalRemove | currency }}</span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-card style="border: 1px solid var(--v-success-base)">
                  <v-card-title class="d-flex flex-column justify-center layout text-center">
                    <h4>{{ $t('log_point_coin.block2_head') }}</h4>
                    <h5 class="mt-2 font-weight-regular">({{ dateFrom }} &nbsp;{{ $t('to') }}&nbsp; {{ dateTo }})</h5>
                  </v-card-title>
                  <v-divider />
                  <v-card-title class="justify-center layout">
                    {{ sumCoin.totalRemain }}
                  </v-card-title>
                  <v-row class="ma-0 mb-4">
                    <v-col cols="6" class="pa-0 d-flex flex-column justify-center align-center">
                      <div class="d-flex flex-row justify-center align-center">
                        <v-icon color="success" small>mdi-arrow-up</v-icon>
                        <span class="success--text d-flex flex-row align-center justify-center">{{
                          $t('log_point_coin.type1')
                        }}</span>
                      </div>
                      <span>{{ sumCoin.totalAdd }}</span>
                    </v-col>
                    <v-col cols="6" class="pa-0 d-flex flex-column justify-center align-center">
                      <div class="d-flex flex-row justify-center align-center">
                        <v-icon color="error" small>mdi-arrow-down</v-icon>
                        <span class="error--text d-flex flex-row align-center justify-center">{{
                          $t('log_point_coin.type2')
                        }}</span>
                      </div>
                      <span>{{ sumCoin.totalRemove }}</span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-card style="border: 1px solid var(--v-success-base)">
                  <v-card-title class="d-flex flex-column justify-center layout text-center">
                    <h4>{{ $t('log_point_coin.block3_head') }}</h4>
                    <h5 class="mt-2 font-weight-regular">({{ dateFrom }} &nbsp;{{ $t('to') }}&nbsp; {{ dateTo }})</h5>
                  </v-card-title>
                  <v-divider />
                  <v-card-title class="justify-center layout">
                    {{ sumMoney.totalRemain | currency }}
                  </v-card-title>
                  <v-row class="ma-0 mb-4">
                    <v-col cols="6" class="pa-0 d-flex flex-column justify-center align-center">
                      <div class="d-flex flex-row justify-center align-center">
                        <v-icon color="success" small>mdi-arrow-up</v-icon>
                        <span class="success--text d-flex flex-row align-center justify-center">{{
                          $t('log_point_coin.type1')
                        }}</span>
                      </div>
                      <span>{{ sumMoney.totalAdd | currency }}</span>
                    </v-col>
                    <v-col cols="6" class="pa-0 d-flex flex-column justify-center align-center">
                      <div class="d-flex flex-row justify-center align-center">
                        <v-icon color="error" small>mdi-arrow-down</v-icon>
                        <span class="error--text d-flex flex-row align-center justify-center">{{
                          $t('log_point_coin.type2')
                        }}</span>
                      </div>
                      <span>{{ sumMoney.totalRemove | currency }}</span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-actions>

        <v-card-actions class="pt-0">
          <v-data-table
            mobile-breakpoint="0"
            style="width: 100%"
            :headers="headers"
            :no-data-text="$t('not_found')"
            :no-results-text="$t('not_found')"
            :options.sync="pagination"
            :server-items-length="pagination.totalItems"
            :items-per-page="pagination.rowsPerPage"
            :loading="loading"
            :page.sync="pagination.currentPage"
            :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }"
            :items="members"
          >
            <!-- <template v-slot:[`item.amount`]="{ item }">
              {{ item.amount | currency }}
            </template> -->
            <template v-slot:[`item.createdAt`]="{ item }">
              <span v-if="item.createdAt">{{ formatDateTime(item.createdAt) }}</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <span
                v-if="item.action && item.action === 'add'"
                class="success--text d-flex flex-row align-center justify-center"
              >
                <v-icon color="success" small>mdi-arrow-up</v-icon>{{ $t('log_point_coin.type1') }}</span
              >
              <span
                v-else-if="item.action && item.action === 'remove'"
                class="error--text d-flex flex-row align-center justify-center"
              >
                <v-icon color="error" small>mdi-arrow-down</v-icon>{{ $t('log_point_coin.type2') }}</span
              >
              <span v-else class="warning--text">-</span>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },
  data() {
    return {
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      currentMemberDetail: {},
      searchData: null,
      time: null,
      time2: '00:00',
      time3: '23:59',
      menu2: false,
      modal2: false,
      menu3: false,
      modal3: false,
      actionType: 'All',
      activityType: 'All',
      itemType: 'All',
      headers: [
        {
          text: this.$t('log_point_coin.header1'),
          value: 'member',
          width: '90px',
          align: 'center',
          sortable: false,
        },

        // {
        //   text: 'โบนัสที่ได้รับ',
        //   value: 'bonus',
        //   width: '100px',
        //   align: 'center',
        //   sortable: false,
        // },
        {
          text: this.$t('log_point_coin.header2'),
          value: 'type',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_point_coin.header3'),
          value: 'amount',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_point_coin.header4'),
          value: 'itemType',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_point_coin.header5'),
          value: 'action',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_point_coin.header6'),
          value: 'detail',
          width: '120px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_point_coin.header7'),
          value: 'createdAt',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        // {
        //   text: 'ดำเนินการโดย',
        //   value: 'member',
        //   width: '90px',
        //   align: 'center',
        //   sortable: false,
        // },
      ],
      members: [],
      sumPoint: {},
      sumCoin: {},
      sumMoney: {},
      actionTypes: [
        { value: 'All', text: this.$t('all') },
        { value: 'add', text: this.$t('log_point_coin.type1') },
        { value: 'remove', text: this.$t('log_point_coin.type2') },
      ],
      activityTypes: [
        { value: 'All', text: this.$t('all') },
        { value: 'luckyCardGame', text: this.$t('log_point_coin.activity1') },
        { value: 'lottoGame', text: this.$t('log_point_coin.activity2') },
        { value: 'predictFootballGame', text: this.$t('log_point_coin.activity3') },
        { value: 'dailylogin', text: this.$t('log_point_coin.activity4') },
        { value: 'deposit', text: this.$t('log_point_coin.activity5') },
        { value: 'swaptocoin', text: this.$t('log_point_coin.activity6') },
        { value: 'turnover', text: this.$t('log_point_coin.activity7') },
        { value: 'luckyWheel', text: this.$t('log_point_coin.activity8') },
      ],
      itemTypes: [
        { value: 'All', text: this.$t('all') },
        { value: 'coin', text: 'Coin' },
        { value: 'point', text: 'Point' },
        { value: 'money', text: 'Money' },
      ],
      loading: false,
      totalRows: 0,
      pagination: {
        currentPage: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      dummy: true,
      queryTerm: '',
    }
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if ((cont && !this.dummy) || (cont && this.dummy))
          await this.getPointCoinHistory(
            newValue.page,
            newValue.itemsPerPage,
            this.$refs.startDate.value,
            this.$refs.endDate.value,
            this.searchData,
            this.activityType,
            this.itemType,
            this.actionType,
          )
      },
      deep: true,
    },
  },
  computed: {
    search: {
      get() {
        return this.queryTerm
      },

      set(searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput
        }
      },
    },
  },
  async created() {
    this.addLogPage()
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          (data = {
            ip: res.data.ip,
            name: 'ระบบ Logs Point-Coin',
            url: window.location.href,
            detail: 'รายการ Logs Point-Coin',
            admin: userSystem.name ? userSystem.name : '',
          }),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async searchPointCoinHistory() {
      this.dummy = false
      await this.getPointCoinHistory(
        1,
        this.pagination.rowsPerPage,
        this.$refs.startDate.value,
        this.$refs.endDate.value,
        this.searchData,
        this.activityType,
        this.itemType,
        this.actionType,
      )
    },
    async getPointCoinHistory(page, row, time_from, time_to, search, type, item, action) {
      this.loading = true
      this.members = []

      let rs = await this.$store.dispatch('getPointCoinHistory', {
        page: page,
        rows: row,
        ...(!search ? { page: page } : {}),
        ...(!search ? { rows: row } : {}),
        ...(time_from ? { time_from: this.formatDateYYYY(time_from) } : {}),
        ...(time_to ? { time_to: this.formatDateYYYY(time_to) } : {}),
        ...(search ? { search: search } : {}),
        ...(type && type !== 'All' ? { type: type } : {}),
        ...(item && item !== 'All' ? { item: item } : {}),
        ...(action && action !== 'All' ? { action: action } : {}),
      })
      this.pagination.totalItems = rs.count
      this.summary = rs.summary
      const defaultData = {
        total: 0,
        totalAdd: 0,
        totalRemove: 0,
        totalRemain: 0,
      }
      this.sumCoin = this.summary.coin || defaultData
      this.sumPoint = this.summary.point || defaultData
      this.sumMoney = this.summary.money || defaultData
      this.totalRows = rs.count
      this.members = rs.data
      this.loading = false
    },
    async searchKeyword(value) {
      this.dummy = true
      this.searchData = value
      await this.getPointCoinHistory(
        1,
        this.pagination.itemsPerPage,
        this.$refs.startDate.value,
        this.$refs.endDate.value,
        value,
        this.activityType,
        this.itemType,
        this.actionType,
      )
      this.pagination.currentPage = 1
    },

    async preloadExportCsv() {
      //////// get logExportData ////////
      let logExportData
      const userSystem = store.getters.getuserInfo
      await axios.get('https://api.ipify.org?format=json').then(res =>
        (logExportData = {
          ip: res.data.ip,
          exportPage: 'ระบบ Logs Point-Coin',
          url: window.location.href,
          detail: 'รายการ Logs Point-Coin',
          admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
        }),
      )

      this.$swal({
        title: `Secure code | รายงาน${logExportData.exportPage}`,
        input: 'password',
        // inputAttributes: {
        //   inputmode: 'numeric',
        //   pattern: '[0-9]*',
        //   maxlength: 6,
        // },
        inputValidator: value => {
          if (!value) {
            return 'โปรดกรอก Secure code'
          }
          // if (value && value.length !== 6) {
          //   return 'โปรดกรอกให้ครบ 6 หลัก'
          // }
        },
        inputPlaceholder: 'ใส่รหัสที่นี่...',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ออก',
      }).then(async result => {
        if (result.isConfirmed) {
          const pin = result.value
          let enterSecureData = {
            ...logExportData,
            keyCheck: pin,
          }
          this.loading = true
          let resEnterSecureData = await this.$store.dispatch('checkLogEnterSecure', enterSecureData)
          if (resEnterSecureData && resEnterSecureData.secure) {
            this.exportCsv()
          } else {
            this.$swal.fire('ไม่สามารถ download ได้ เนื่องจากรหัส PIN ไม่ถูกต้อง', '', 'error')
          }
          this.loading = false
        }
      })
    },
    async exportCsv() {
      this.$swal.fire({
        html: this.$t('processing'),
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading()
        },
      })

      let fileName = `รายงาน Logs Point-Coin_${moment(new Date()).format('YYYY-MM-DD_HH-mm')}`
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'
      const wscols = [{ wch: 20 }, { wch: 15 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 40 }, { wch: 20 }]
      let statementlist = []
      let rs = await this.$store.dispatch('getPointCoinHistory', {
        page: 1,
        rows: 1000000,
        ...(this.$refs.startDate.value ? { time_from: this.formatDateYYYY(this.$refs.startDate.value) } : {}),
        ...(this.$refs.endDate.value ? { time_to: this.formatDateYYYY(this.$refs.endDate.value) } : {}),
        ...(this.searchData ? { search: this.searchData } : {}),
        ...(this.activityType && this.activityType !== 'All' ? { type: this.activityType } : {}),
        ...(this.itemType && this.itemType !== 'All' ? { item: this.itemType } : {}),
        ...(this.actionType && this.actionType !== 'All' ? { action: this.actionType } : {}),
      })
      rs.data.forEach(element => {
        statementlist.push({
          date: element.created_at ? moment(element.created_at).format('YYYY-MM-DD HH:mm') : '',
          member: element.member ? String(element.member.phone) : null,
          amount: element.amount,
          bonus: element.bonus,
          type: element.type,
          detail: element.detail,
          // by: element.member ? String(element.member.phone) : null,
        })
      })
      let ws = XLSX.utils.json_to_sheet(statementlist)
      ws['!cols'] = wscols
      const wb = { Sheets: { LogPointCoinReport: ws }, SheetNames: ['LogPointCoinReport'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, fileName + fileExtension)

      // add log admin export
      await this.addLogExportCSV()
      this.$swal.close()
    },
    async addLogExportCSV() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          (data = {
            ip: res.data.ip,
            exportPage: 'ระบบ Logs Point-Coin',
            url: window.location.href,
            detail: 'รายการ Logs Point-Coin',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }),
        )
        await this.$store.dispatch('addLogExportCSV', data)
      } catch (e) {
        if (
          !e.message.includes('Unexpected token') ||
          !e?.includes('Unexpected token') ||
          !e.message.includes('Unexpected identifier')
        ) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
    },

    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
    formatDateTime(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>
<style scoped>
@import '../../styles/custom.css';
</style>
